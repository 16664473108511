import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { useContext, useEffect, useState } from "react";
import { SearchPageContext } from "@contexts/SearchPageContext";
import { SEARCH_PAGE_ACTION_TYPES } from "@contexts/SearchPageContext/search-page.actions";
var typenameMapping = {
    company: "Company",
    product: "Product",
    moleculartarget: "Target",
    diseasecategory: "Disease Category",
    standardindication: "Disease Category"
};
var useHandleSetEntity = function() {
    var ref = useContext(SearchPageContext), _state = ref.state, selectedEntities = _state.selectedEntities, isSomeEntityDeleted = _state.isSomeEntityDeleted, dispatch = ref.dispatch;
    var ref1 = useState([]), entities = ref1[0], setEntities = ref1[1];
    useEffect(function() {
        if (entities.length === 0) {
            return;
        }
        dispatch({
            type: SEARCH_PAGE_ACTION_TYPES.SET_ENTITIES,
            data: entities
        });
    }, [
        entities
    ]);
    useEffect(function() {
        var _entities;
        (_entities = entities).splice.apply(_entities, [
            0,
            entities.length
        ].concat(_to_consumable_array(selectedEntities)));
    }, [
        isSomeEntityDeleted
    ]);
    var handleSetEntity = function(item) {
        var completeName = item.completeName;
        if (item.searchBy && isNaN(item.searchBy)) {
            completeName = item.searchBy.replace(/(?=\|).*|\(.*?\)/g, "");
        }
        var existingEntity = entities.filter(function(entity) {
            return entity.completeName === completeName;
        }).length > 0;
        if (!existingEntity) {
            setEntities(_to_consumable_array(selectedEntities).concat([
                {
                    type: item.__typename || typenameMapping[item === null || item === void 0 ? void 0 : item.dimension],
                    completeName: completeName,
                    encodedName: item.encodedName
                }, 
            ]));
        }
    };
    return handleSetEntity;
};
export default useHandleSetEntity;
