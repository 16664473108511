import { CategoryFacetKeys, ContentTypeFacetKeys, PublicationDateFacetKeys } from "./search-page-definitions";
export var categoryFacets = [
    {
        name: "Commentaries",
        categories: [
            "categories:BioCentury Commentary",
            "categories:Guest Commentary",
            "categories:Editor's Commentary",
            "categories:Commentaries", 
        ],
        key: CategoryFacetKeys.COMMENTARIES
    },
    {
        name: "Data Byte",
        categories: [
            "categories:Data Byte"
        ],
        key: CategoryFacetKeys.DATA_BYTE
    },
    {
        name: "Deals",
        categories: [
            "categories:Deals"
        ],
        key: CategoryFacetKeys.DEALS
    },
    {
        name: "Discovery & Translation",
        categories: [
            "categories:Discovery & Translation",
            "categories:Targets & Mechanisms",
            "categories:Tools & Techniques",
            "categories:Translation in Brief", 
        ],
        key: CategoryFacetKeys.DISCOVERY_TRANSLATION
    },
    {
        name: "Distillery",
        categories: [
            "categories:Distillery Techniques",
            "categories:Distillery Therapeutics"
        ],
        key: CategoryFacetKeys.DISTILLERY
    },
    {
        name: "Emerging Companies",
        categories: [
            "categories:Emerging Company Profile"
        ],
        key: CategoryFacetKeys.EMERGING_COMPANIES
    },
    {
        name: "Finance",
        categories: [
            "categories:Finance",
            "categories:Financial News"
        ],
        key: CategoryFacetKeys.FINANCE
    },
    {
        name: "Management Tracks",
        categories: [
            "categories:Management Tracks"
        ],
        key: CategoryFacetKeys.MANAGEMENT_TRACKS
    },
    {
        name: "Market Access",
        categories: [
            "categories:Market Access"
        ],
        key: CategoryFacetKeys.MARKET_ACCESS
    },
    {
        name: "Politics, Policy & Law",
        categories: [
            "categories:Politics, Policy & Law",
            "categories:Politics & Policy",
            "categories:Politics & Policy News", 
        ],
        key: CategoryFacetKeys.POLITICS_POLICY_LAW
    },
    {
        name: "Product Development",
        categories: [
            "categories:Product R&D",
            "categories:Product Development"
        ],
        key: CategoryFacetKeys.PRODUCT_DEVELOPMENT
    },
    {
        name: "Regulation",
        categories: [
            "categories:Regulation"
        ],
        key: CategoryFacetKeys.REGULATION
    }, 
];
export var contentTypeFacets = [
    {
        name: "Article",
        key: ContentTypeFacetKeys.ARTICLES
    },
    {
        name: "Data Graphic",
        key: ContentTypeFacetKeys.DATA_GRAPHICS
    },
    {
        name: "Podcast",
        key: ContentTypeFacetKeys.PODCASTS
    },
    {
        name: "Presentation",
        key: ContentTypeFacetKeys.PRESENTATIONS
    },
    {
        name: "White Paper",
        key: ContentTypeFacetKeys.WHITEPAPERS
    },
    {
        name: "Webcast",
        key: ContentTypeFacetKeys.WEBCASTS
    }, 
];
export var publicationDateFacets = [
    {
        name: "Any",
        key: PublicationDateFacetKeys.ANY
    },
    {
        name: "Past 7 Days",
        key: PublicationDateFacetKeys.PAST_7_DAYS
    },
    {
        name: "Past 30 Days",
        key: PublicationDateFacetKeys.PAST_30_DAYS
    },
    {
        name: "Past 12 Months",
        key: PublicationDateFacetKeys.PAST_12_MONTHS
    },
    {
        name: "Range",
        key: PublicationDateFacetKeys.RANGE
    }, 
];
