import _define_property from "@swc/helpers/src/_define_property.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import regeneratorRuntime from "/home/vsts/work/1/s/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useReducer, useState } from "react";
import { useHasMounted } from "@biocentury/components";
import { Algolia } from "@biocentury/core";
import { ModelType } from "@biocentury/core/types";
import { CategoryFacetKeys, ContentTypeFacetKeys, PublicationDateTypes, SortByKeys } from "@contexts/SearchPageContext/search-page-definitions";
import { SEARCH_PAGE_ACTION_TYPES } from "@contexts/SearchPageContext/search-page.actions";
import reducer, { initialState } from "@contexts/SearchPageContext/search-page.reducers";
import { useQuery } from "src/hooks/useQuery";
var __awaiter = this && this.__awaiter || function(thisArg, _arguments, P, generator) {
    var adopt = function adopt(value) {
        return _instanceof(value, P) ? value : new P(function(resolve) {
            resolve(value);
        });
    };
    return new (P || (P = Promise))(function(resolve, reject) {
        var fulfilled = function fulfilled(value) {
            try {
                step(generator.next(value));
            } catch (e) {
                reject(e);
            }
        };
        var rejected = function rejected(value) {
            try {
                step(generator["throw"](value));
            } catch (e) {
                reject(e);
            }
        };
        var step = function step(result) {
            result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
        };
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var useSearch = function() {
    var prepareSearch = function prepareSearch() {
        return __awaiter(this, void 0, void 0, regeneratorRuntime.mark(function _callee() {
            var resp, status, body;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.prev = 0;
                        _ctx.next = 3;
                        return fetch("api/preparesearch".concat(params));
                    case 3:
                        resp = _ctx.sent;
                        status = resp.status;
                        if (!(status === 200)) {
                            _ctx.next = 11;
                            break;
                        }
                        _ctx.next = 8;
                        return resp.json();
                    case 8:
                        _ctx.t0 = _ctx.sent;
                        _ctx.next = 12;
                        break;
                    case 11:
                        _ctx.t0 = null;
                    case 12:
                        body = _ctx.t0;
                        if (body) {
                            _ctx.next = 15;
                            break;
                        }
                        return _ctx.abrupt("return");
                    case 15:
                        if (body) {
                            setOldData(body);
                        }
                        _ctx.next = 21;
                        break;
                    case 18:
                        _ctx.prev = 18;
                        _ctx.t1 = _ctx["catch"](0);
                        console.log(_ctx.t1);
                    case 21:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    0,
                    18
                ]
            ]);
        }));
    };
    var setSearchFromOldData = function setSearchFromOldData(oldData) {
        var _obj, _obj1;
        dispatch({
            type: SEARCH_PAGE_ACTION_TYPES.SET_SEARCH_FROM_PARAMS,
            data: (_obj1 = {
                searchTerm: oldData.q,
                indexPageNumber: 1,
                searchHeadlinesOnly: oldData.aho === "true",
                isCategoryFacetKeysDirty: true,
                categoryFacetKeys: (_obj = {}, _define_property(_obj, CategoryFacetKeys.ALL, null), _define_property(_obj, CategoryFacetKeys.COMMENTARIES, oldData.brs.commentaries), _define_property(_obj, CategoryFacetKeys.DEALS, oldData.brs.deals), _define_property(_obj, CategoryFacetKeys.DISCOVERY_TRANSLATION, oldData.brs.discoveryTranslation), _define_property(_obj, CategoryFacetKeys.DISTILLERY, oldData.brs.distillery), _define_property(_obj, CategoryFacetKeys.EMERGING_COMPANIES, oldData.brs.emergingCompanies), _define_property(_obj, CategoryFacetKeys.FINANCE, oldData.brs.finance), _define_property(_obj, CategoryFacetKeys.MANAGEMENT_TRACKS, oldData.brs.managementTracks), _define_property(_obj, CategoryFacetKeys.MARKET_ACCESS, oldData.brs.marketAccess), _define_property(_obj, CategoryFacetKeys.POLITICS_POLICY_LAW, oldData.brs.politicsPolicyLaw), _define_property(_obj, CategoryFacetKeys.PRODUCT_DEVELOPMENT, oldData.brs.productDevelopment), _define_property(_obj, CategoryFacetKeys.REGULATION, oldData.brs.regulation), _define_property(_obj, CategoryFacetKeys.DATA_BYTE, oldData.brs.dataByte), _obj),
                dateRange: oldData.dateRange
            }, _define_property(_obj1, PublicationDateTypes.START_DATE, new Date(oldData.dateFrom)), _define_property(_obj1, PublicationDateTypes.END_DATE, new Date(oldData.dateTo)), _define_property(_obj1, "selectedEntities", oldData.entities), _define_property(_obj1, "isSearchLoading", true), _define_property(_obj1, "sortBy", SortByKeys.NEWEST_FIRST), _define_property(_obj1, "bcxTopStory", oldData.bcx), _define_property(_obj1, "featuredStory", oldData.fts), _obj1)
        });
    };
    var setSearchFromNewData = function setSearchFromNewData() {
        var data = Algolia.useTransformUrlToData(query);
        var _obj, _obj1, _obj2;
        dispatch({
            type: SEARCH_PAGE_ACTION_TYPES.SET_SEARCH_FROM_PARAMS,
            data: (_obj2 = {
                searchTerm: data.q,
                searchHeadlinesOnly: data.aho,
                indexPageNumber: data.pn,
                isCategoryFacetKeysDirty: true,
                categoryFacetKeys: (_obj = {}, _define_property(_obj, CategoryFacetKeys.ALL, data.brs.all), _define_property(_obj, CategoryFacetKeys.COMMENTARIES, data.brs.commentaries), _define_property(_obj, CategoryFacetKeys.DEALS, data.brs.deals), _define_property(_obj, CategoryFacetKeys.DISCOVERY_TRANSLATION, data.brs.discoverytranslation), _define_property(_obj, CategoryFacetKeys.DISTILLERY, data.brs.distillery), _define_property(_obj, CategoryFacetKeys.EMERGING_COMPANIES, data.brs.emergingcompanies), _define_property(_obj, CategoryFacetKeys.FINANCE, data.brs.finance), _define_property(_obj, CategoryFacetKeys.MANAGEMENT_TRACKS, data.brs.managementtracks), _define_property(_obj, CategoryFacetKeys.MARKET_ACCESS, data.brs.marketaccess), _define_property(_obj, CategoryFacetKeys.POLITICS_POLICY_LAW, data.brs.politicspolicylaw), _define_property(_obj, CategoryFacetKeys.PRODUCT_DEVELOPMENT, data.brs.productdevelopment), _define_property(_obj, CategoryFacetKeys.REGULATION, data.brs.regulation), _define_property(_obj, CategoryFacetKeys.DATA_BYTE, data.brs.databyte), _obj),
                contentTypeFacetKeys: (_obj1 = {}, _define_property(_obj1, ContentTypeFacetKeys.ALL, data.ct.all), _define_property(_obj1, ContentTypeFacetKeys.ARTICLES, data.ct.Articles), _define_property(_obj1, ContentTypeFacetKeys.PODCASTS, data.ct.Podcasts), _define_property(_obj1, ContentTypeFacetKeys.DATA_GRAPHICS, data.ct.Datagraphics), _define_property(_obj1, ContentTypeFacetKeys.WHITEPAPERS, data.ct.Whitepapers), _define_property(_obj1, ContentTypeFacetKeys.REPORTS, data.ct.Reports), _define_property(_obj1, ContentTypeFacetKeys.PRESENTATIONS, data.ct.Presentations), _define_property(_obj1, ContentTypeFacetKeys.WEBCASTS, data.ct.Webcasts), _obj1),
                dateRange: data.dateRange
            }, _define_property(_obj2, PublicationDateTypes.START_DATE, data.dateFrom ? new Date(data.dateFrom.toString().replace(/-/g, "/")) : new Date(data.dateFrom)), _define_property(_obj2, PublicationDateTypes.END_DATE, data.dateFrom ? new Date(data.dateTo.toString().replace(/-/g, "/")) : new Date(data.dateTo)), _define_property(_obj2, "selectedEntities", data.entities), _define_property(_obj2, "isSearchLoading", true), _define_property(_obj2, "sortBy", data.sortBy === SortByKeys.OLDEST_FIRST ? SortByKeys.OLDEST_FIRST : SortByKeys.NEWEST_FIRST), _define_property(_obj2, "bcxTopStory", data.bcx), _define_property(_obj2, "featuredStory", data.fts), _obj2)
        });
    };
    var createSearchUrl = function createSearchUrl() {};
    var addFilterToQuery = function addFilterToQuery(query, filter) {
        if (!query.params.filters) {
            query.params.filters = filter;
        } else {
            query.params.filters += " AND ".concat(filter);
        }
    };
    var getSearchQueries = function getSearchQueries() {
        var queries = Algolia.parseFromString(asPath);
        var analysisTabQuery = cloneDeep(queries);
        addFilterToQuery(analysisTabQuery, "__typename:".concat(ModelType.Article));
        var communityTabQuery = cloneDeep(queries);
        addFilterToQuery(communityTabQuery, "__typename:Events");
        var dataTabQuery = cloneDeep(queries);
        if (!dataTabQuery.params.filters) {
            dataTabQuery.params.filters = "section:BCIQ";
        } else {
            dataTabQuery.params.filters += " AND section:BCIQ";
            dataTabQuery.params.filters = dataTabQuery.params.filters.replace(/.?authoringDateTime.*?AND /, "");
        }
        return [
            analysisTabQuery,
            dataTabQuery,
            communityTabQuery
        ];
    };
    var handleSearch = function handleSearch() {
        return __awaiter(this, void 0, void 0, regeneratorRuntime.mark(function _callee() {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        Algolia.search(getSearchQueries()).then(function(param) {
                            var results = param.results;
                            dispatch({
                                type: SEARCH_PAGE_ACTION_TYPES.SET_SEARCH_RESULT,
                                data: {
                                    allTabResults: results[0].hits,
                                    nbAllTabHits: results[0].nbHits,
                                    nbAllTabResultsPage: results[0].nbPages,
                                    analysisTabResults: results[0].hits,
                                    nbAnalysisTabResultsPage: results[0].nbPages,
                                    nbAnalysisTabHits: results[0].nbHits,
                                    dataTabResults: results[1].hits,
                                    nbDataTabHits: results[1].nbHits,
                                    nbDataTabResultsPage: results[1].nbPages,
                                    eventsTabResults: results[2].hits,
                                    nbEventsTabHits: results[2].nbHits,
                                    nbEventsTabResultsPage: results[2].nbPages
                                }
                            });
                        });
                    case 1:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
    };
    var _a, _b;
    var ref = useReducer(reducer, initialState), state = ref[0], dispatch = ref[1];
    var ref1 = useState(), oldData = ref1[0], setOldData = ref1[1];
    var ref2 = useState(""), asPath = ref2[0], setAsPath = ref2[1];
    var ref3 = useState(false), ready = ref3[0], setReady = ref3[1];
    var router = useQuery();
    var params = (_b = (_a = router === null || router === void 0 ? void 0 : router.asPath) === null || _a === void 0 ? void 0 : _a.match(/\?.*/)) === null || _b === void 0 ? void 0 : _b[0];
    var query = router === null || router === void 0 ? void 0 : router.query;
    var mounted = useHasMounted();
    useEffect(function() {
        if (!router.isReady) return;
        if (!params) {
            createSearchUrl();
        } else if (!(params === null || params === void 0 ? void 0 : params.includes("v=2"))) {
            prepareSearch();
        } else {
            setSearchFromNewData();
        }
        setReady(true);
    }, [
        router.isReady
    ]);
    useEffect(function() {
        if (!ready) return;
        setAsPath(router === null || router === void 0 ? void 0 : router.asPath);
    }, [
        ready,
        router === null || router === void 0 ? void 0 : router.asPath
    ]);
    useEffect(function() {
        if (params && !(params === null || params === void 0 ? void 0 : params.includes("v=2"))) {
            prepareSearch();
        }
    }, []);
    useEffect(function() {
        if (oldData) {
            setSearchFromOldData(oldData);
        }
    }, [
        oldData
    ]);
    useEffect(function() {
        if (ready && params) {
            Algolia.createUrl(state, router);
        }
    }, [
        state
    ]);
    useEffect(function() {
        if (ready && mounted && router.asPath !== "/search") {
            handleSearch();
        }
    }, [
        asPath
    ]);
    return {
        state: state,
        dispatch: dispatch
    };
};
export default useSearch;
