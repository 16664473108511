export var CategoryFacetKeys;
(function(CategoryFacetKeys) {
    CategoryFacetKeys["ALL"] = "all";
    CategoryFacetKeys["COMMENTARIES"] = "commentaries";
    CategoryFacetKeys["DEALS"] = "deals";
    CategoryFacetKeys["DISCOVERY_TRANSLATION"] = "discoveryTranslation";
    CategoryFacetKeys["DISTILLERY"] = "distillery";
    CategoryFacetKeys["EMERGING_COMPANIES"] = "emergingCompanies";
    CategoryFacetKeys["FINANCE"] = "finance";
    CategoryFacetKeys["MANAGEMENT_TRACKS"] = "managementTracks";
    CategoryFacetKeys["MARKET_ACCESS"] = "marketAccess";
    CategoryFacetKeys["POLITICS_POLICY_LAW"] = "politicsPolicyLaw";
    CategoryFacetKeys["PRODUCT_DEVELOPMENT"] = "productDevelopment";
    CategoryFacetKeys["REGULATION"] = "regulation";
    CategoryFacetKeys["DATA_BYTE"] = "dataByte";
})(CategoryFacetKeys || (CategoryFacetKeys = {}));
export var ContentTypeFacetKeys;
(function(ContentTypeFacetKeys) {
    ContentTypeFacetKeys["ALL"] = "all";
    ContentTypeFacetKeys["ARTICLES"] = "Articles";
    ContentTypeFacetKeys["PODCASTS"] = "Podcasts";
    ContentTypeFacetKeys["DATA_GRAPHICS"] = "Datagraphics";
    ContentTypeFacetKeys["WHITEPAPERS"] = "Whitepapers";
    ContentTypeFacetKeys["PRESENTATIONS"] = "Presentations";
    ContentTypeFacetKeys["REPORTS"] = "Reports";
    ContentTypeFacetKeys["WEBCASTS"] = "Webcasts";
})(ContentTypeFacetKeys || (ContentTypeFacetKeys = {}));
export var SortByKeys;
(function(SortByKeys) {
    SortByKeys["NEWEST_FIRST"] = "Newest First";
    SortByKeys["OLDEST_FIRST"] = "Oldest First";
    SortByKeys["RELEVANCE"] = "Relevance";
})(SortByKeys || (SortByKeys = {}));
export var ComponentKeys;
(function(ComponentKeys) {
    ComponentKeys["SORT_BY"] = "SortBy";
    ComponentKeys["FILTER_BY"] = "FilterBy";
    ComponentKeys["SEARCH_TIPS"] = "SearchTips";
    ComponentKeys["SAVE_SEARCH"] = "SaveSearch";
    ComponentKeys["CATEGORY"] = "Category";
    ComponentKeys["PUBLICATION_DATE"] = "Publication Date";
    ComponentKeys["CONTENT_TYPE"] = "Content Type";
    ComponentKeys["PRODUCT"] = "Product";
    ComponentKeys["DISEASE_CATEGORY"] = "Disease Category";
    ComponentKeys["COMPANY"] = "Company";
    ComponentKeys["MOLECULAR_TARGET"] = "Molecular Target";
})(ComponentKeys || (ComponentKeys = {}));
export var PublicationDateFacetKeys;
(function(PublicationDateFacetKeys) {
    PublicationDateFacetKeys["ANY"] = "Any Time";
    PublicationDateFacetKeys["PAST_7_DAYS"] = "Past 7 Days";
    PublicationDateFacetKeys["PAST_30_DAYS"] = "Past 30 Days";
    PublicationDateFacetKeys["PAST_12_MONTHS"] = "Past 12 Months";
    PublicationDateFacetKeys["RANGE"] = "range";
})(PublicationDateFacetKeys || (PublicationDateFacetKeys = {}));
export var IndexResponseKey;
(function(IndexResponseKey) {
    IndexResponseKey["ARTICLE"] = "articleResult";
    IndexResponseKey["COMPANY"] = "companyResult";
    IndexResponseKey["TARGET"] = "targetResult";
    IndexResponseKey["PRODUCT"] = "productResult";
    IndexResponseKey["COMMUNITY"] = "communityResult";
    IndexResponseKey["DISEASE"] = "diseaseCategoryResult";
})(IndexResponseKey || (IndexResponseKey = {}));
export var PublicationDateTypes;
(function(PublicationDateTypes) {
    PublicationDateTypes["START_DATE"] = "startDate";
    PublicationDateTypes["END_DATE"] = "endDate";
})(PublicationDateTypes || (PublicationDateTypes = {}));
