import { useEffect, useCallback, useState } from "react";
function getRect(element) {
    var rect = {
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0
    };
    if (element) rect = element.getBoundingClientRect();
    return rect;
}
export function useRect(ref) {
    var _$ref = useState(ref && ref.current ? getRect(ref.current) : getRect()), rect = _$ref[0], setRect = _$ref[1];
    var handleResize = useCallback(function() {
        if (!ref.current) return;
        setRect(getRect(ref.current));
    }, [
        ref
    ]);
    useEffect(function() {
        var element = ref.current;
        if (!element) return;
        handleResize();
        if (typeof ResizeObserver === "function") {
            var resizeObserver = new ResizeObserver(function() {
                return handleResize();
            });
            resizeObserver.observe(element);
            return function() {
                if (!resizeObserver) return;
                resizeObserver.disconnect();
            };
        }
        window.addEventListener("resize", handleResize);
        return function() {
            return window.removeEventListener("resize", handleResize);
        };
    }, [
        ref.current
    ]);
    return rect;
}
