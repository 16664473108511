export var profileTypesData = {
    companies: {
        indexName: "".concat(process.env.NEXT_PUBLIC_ALGOLIA_COMPANY_INDEX_NAME),
        label: "Company",
        completeNameKey: "completeName",
        name: "company",
        placeholder: "Enter a company name"
    },
    targets: {
        indexName: "".concat(process.env.NEXT_PUBLIC_ALGOLIA_TARGET_INDEX_NAME),
        label: "Molecular Target",
        completeNameKey: "legacyTargetName",
        placeholder: "Enter a molecular name",
        name: "molecular-target"
    },
    products: {
        indexName: "".concat(process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_NAME),
        label: "Product",
        completeNameKey: "completeName",
        placeholder: "Enter a product name",
        name: "product"
    },
    diseaseCategories: {
        indexName: "".concat(process.env.NEXT_PUBLIC_ALGOLIA_DISEASE_CATEGORY_INDEX_NAME),
        label: "Disease Category",
        completeNameKey: "completeName",
        placeholder: "Enter a disease category name",
        name: "disease-category"
    }
};
