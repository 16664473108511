import { Content } from "./Content";
import { Home } from "./Home";
import { Marketing } from "./Marketing";
import { Search } from "./Search";
export var Layouts = {
    Content: Content,
    Home: Home,
    Marketing: Marketing,
    Search: Search
};
