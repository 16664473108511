import { ComponentKeys, SortByKeys } from "@contexts/SearchPageContext/search-page-definitions";
export var sortByValues = [
    {
        label: "Newest First",
        value: SortByKeys.NEWEST_FIRST
    },
    {
        label: "Oldest First",
        value: SortByKeys.OLDEST_FIRST
    }, 
];
export var filterByValues = [
    {
        label: "Category",
        componentKey: ComponentKeys.CATEGORY
    },
    {
        label: "Publication Date",
        componentKey: ComponentKeys.PUBLICATION_DATE
    },
    {
        label: "Company",
        componentKey: ComponentKeys.COMPANY
    },
    {
        label: "Product",
        componentKey: ComponentKeys.PRODUCT
    },
    {
        label: "Molecular Target",
        componentKey: ComponentKeys.MOLECULAR_TARGET
    },
    {
        label: "Disease Category",
        componentKey: ComponentKeys.DISEASE_CATEGORY
    },
    {
        label: "Content Type",
        componentKey: ComponentKeys.CONTENT_TYPE
    }, 
];
