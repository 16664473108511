export var SEARCH_PAGE_ACTION_TYPES;
(function(SEARCH_PAGE_ACTION_TYPES) {
    SEARCH_PAGE_ACTION_TYPES["SET_SEARCH_TERM"] = "[Search Page] Set Search Term";
    SEARCH_PAGE_ACTION_TYPES["GET_SEARCH_RESULT"] = "[Search Page] Get Search result";
    SEARCH_PAGE_ACTION_TYPES["SET_SEARCH_RESULT"] = "[Search Page] Set Search result";
    SEARCH_PAGE_ACTION_TYPES["SET_SEARCH_HEADLINES_ONLY"] = "[Search Page] Set Search Headlines Only";
    SEARCH_PAGE_ACTION_TYPES["SET_SEARCH_NEWEST_FIRST"] = "[Search Page] Set Search Newest First";
    SEARCH_PAGE_ACTION_TYPES["SET_SEARCH_OLDEST_FIRST"] = "[Search Page] Set Search Oldest First";
    SEARCH_PAGE_ACTION_TYPES["SET_SEARCH_RELEVANCE"] = "[Search Page] Set Search Relevance";
    SEARCH_PAGE_ACTION_TYPES["CHANGE_CATEGORY_FACET"] = "[Search Page] Change Category Facet";
    SEARCH_PAGE_ACTION_TYPES["CHANGE_SORT_BY_FACET"] = "[Search Page] Change Sort By Facet";
    SEARCH_PAGE_ACTION_TYPES["CHANGE_CONTENT_TYPE_FACET"] = "[Search Page] Change Content Type Facet";
    SEARCH_PAGE_ACTION_TYPES["CHANGE_COMPANY_DATA"] = "[Search Page] Change Company Data";
    SEARCH_PAGE_ACTION_TYPES["CHANGE_PRODUCT_DATA"] = "[Search Page] Change Product Data";
    SEARCH_PAGE_ACTION_TYPES["CHANGE_TARGET_DATA"] = "[Search Page] Change Target Data";
    SEARCH_PAGE_ACTION_TYPES["CHANGE_DISEASE_CATEGORY_DATA"] = "[Search Page] Change Disease Category Data";
    SEARCH_PAGE_ACTION_TYPES["CHANGE_PUBLICATION_DATE_FACET"] = "[Search Page] Change Publication Date Facet";
    SEARCH_PAGE_ACTION_TYPES["CLEAR_FILTERS"] = "[Search Page] Clear Filters";
    SEARCH_PAGE_ACTION_TYPES["CHANGE_MOBILE_MODAL_COMPONENT"] = "[Search Page] Change Mobile Modal Component";
    SEARCH_PAGE_ACTION_TYPES["GET_PREV_MODAL_COMPONENT"] = "[Search Page] Get Prev Modal Component";
    SEARCH_PAGE_ACTION_TYPES["SET_SEARCH_FROM_PARAMS"] = "[Search Page] Set Search From Params";
    SEARCH_PAGE_ACTION_TYPES["SET_ENTITIES"] = "[Search Page] Set Entities";
    SEARCH_PAGE_ACTION_TYPES["REMOVE_ENTITY"] = "[Search Page] Remove Entities";
    SEARCH_PAGE_ACTION_TYPES["SET_DATA_TAB_RESULTS"] = "[Search Page] Set Data Tab Results";
    SEARCH_PAGE_ACTION_TYPES["SET_ANALYSIS_TAB_RESULTS"] = "[Search Page] Set Analysis Tab Results";
    SEARCH_PAGE_ACTION_TYPES["SET_EVENTS_TAB_RESULTS"] = "[Search Page] Set Events Tab Results";
    SEARCH_PAGE_ACTION_TYPES["SET_TAB"] = "[Search Page] Set Tab";
    SEARCH_PAGE_ACTION_TYPES["SET_LOAD_MORE"] = "[Search Page] Set Load More";
    SEARCH_PAGE_ACTION_TYPES["SET_ALL_TAB_RESULTS"] = "[Search Page] Set All Tab Results";
    SEARCH_PAGE_ACTION_TYPES["SET_OPEN_SOME_DISCLOSURE"] = "[Search Page] Set Open Some Disclosure";
})(SEARCH_PAGE_ACTION_TYPES || (SEARCH_PAGE_ACTION_TYPES = {}));
