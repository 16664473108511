export var baseUrl = process.env.NEXT_PUBLIC_BIOCENTURY_URL;
export var indexUrl = process.env.NEXT_PUBLIC_INDEX_API_URL;
export var conferencesUrl = process.env.NEXT_PUBLIC_CONFERENCES_URL;
export var companyProfileUrl = "".concat(process.env.NEXT_PUBLIC_BCIQ_PROFILES_URL, "/companies");
export var targetProfileUrl = "".concat(process.env.NEXT_PUBLIC_BCIQ_PROFILES_URL, "/targets");
export var productProfileUrl = "".concat(process.env.NEXT_PUBLIC_BCIQ_PROFILES_URL, "/products");
export var diseaseProfileUrl = "".concat(process.env.NEXT_PUBLIC_BCIQ_PROFILES_URL, "/diseases");
export var ArticlesEndpoints;
(function(ArticlesEndpoints) {
    ArticlesEndpoints["DECRYPT_ARTICLE"] = "/api/article";
    ArticlesEndpoints["GET_ARTICLES_EDITION"] = "/api/edition";
})(ArticlesEndpoints || (ArticlesEndpoints = {}));
export var MainDatabaseEndpoints = {
    FETCH_ENTITIES: "".concat(process.env.BIOCENTURY_API_URL, "/search/v1/entityfetch/:modelType"),
    FETCH_ENTITIES_WITH_PAGE_SIZE: "".concat(process.env.BIOCENTURY_API_URL, "/search/v1/entityfetch/:modelType?pageSize=:pageSize&pageNumber=0")
};
