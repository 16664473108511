import { useEffect, useState } from "react";
export function useWindowSize() {
    var ref = useState({
        windowWidth: 0,
        windowHeight: 0
    }), windowSize = ref[0], setWindowSize = ref[1];
    useEffect(function() {
        var handleResize = function handleResize() {
            setWindowSize({
                windowWidth: (window === null || window === void 0 ? void 0 : window.innerWidth) || 0,
                windowHeight: (window === null || window === void 0 ? void 0 : window.innerHeight) || 0
            });
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return function() {
            return window.removeEventListener("resize", handleResize);
        };
    }, []);
    return windowSize;
}
