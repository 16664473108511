import { SearchPageContext } from "@contexts/SearchPageContext";
import { TabContext } from "@contexts/TabContext";
import { useContext, useState } from "react";
import { SEARCH_PAGE_ACTION_TYPES } from "@contexts/SearchPageContext/search-page.actions";
import compact from "lodash/compact";
import flow from "lodash/flow";
export var useSearchResultsComponent = function() {
    var ref = useState(true), showPaginationState = ref[0], setShowPaginationState = ref[1];
    var ref1 = useContext(SearchPageContext), _state = ref1.state, articleResult = _state.articleResult, isSearchLoading = _state.isSearchLoading, dataTabResults = _state.dataTabResults, analysisTabResults = _state.analysisTabResults, eventsTabResults = _state.eventsTabResults, indexPageNumber = _state.indexPageNumber, allTabResults = _state.allTabResults, nbAnalysisTabHits = _state.nbAnalysisTabHits, nbDataTabHits = _state.nbDataTabHits, nbEventsTabHits = _state.nbEventsTabHits, selectedEntities = _state.selectedEntities, dispatch = ref1.dispatch;
    var activeTab = useContext(TabContext).activeTab;
    var companyData = selectedEntities.filter(function(entity) {
        return entity.type === "Company";
    });
    var productData = selectedEntities.filter(function(entity) {
        return entity.type === "Product";
    });
    var targetData = selectedEntities.filter(function(entity) {
        return entity.type === "Target";
    });
    var diseaseCategoryData = selectedEntities.filter(function(entity) {
        return entity.type === "Disease Category";
    });
    var eventsData = flow([
        function(results) {
            return results === null || results === void 0 ? void 0 : results.map(function(item) {
                if (item.__typename === "Conferences") {
                    return {
                        id: item === null || item === void 0 ? void 0 : item.objectID,
                        type: item === null || item === void 0 ? void 0 : item.__typename,
                        external: true,
                        title: item.name
                    };
                }
            });
        },
        compact, 
    ])(allTabResults);
    var dataTabData = dataTabResults === null || dataTabResults === void 0 ? void 0 : dataTabResults.map(function(item) {
        return {
            id: item === null || item === void 0 ? void 0 : item.objectID,
            encodedName: item.encodedName,
            type: item === null || item === void 0 ? void 0 : item.__typename,
            external: true,
            title: item.completeName,
            isArchived: item.isArchived
        };
    });
    var handleNextPage = function(num) {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        dispatch({
            type: SEARCH_PAGE_ACTION_TYPES.SET_LOAD_MORE,
            data: Number(indexPageNumber) + num
        });
    };
    var handlePrevPage = function(num) {
        if (indexPageNumber > 0) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            dispatch({
                type: SEARCH_PAGE_ACTION_TYPES.SET_LOAD_MORE,
                data: Number(indexPageNumber) - num
            });
        }
    };
    var handleFirstPage = function(num) {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        dispatch({
            type: SEARCH_PAGE_ACTION_TYPES.SET_LOAD_MORE,
            data: num
        });
    };
    var handleLastPage = function(num) {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        dispatch({
            type: SEARCH_PAGE_ACTION_TYPES.SET_LOAD_MORE,
            data: num
        });
    };
    return {
        models: {
            indexPageNumber: indexPageNumber,
            showPaginationState: showPaginationState,
            companyData: companyData,
            targetData: targetData,
            productData: productData,
            diseaseCategoryData: diseaseCategoryData,
            isSearchLoading: isSearchLoading,
            activeTab: activeTab,
            articleResult: articleResult,
            dataTabResults: dataTabResults,
            analysisTabResults: analysisTabResults,
            eventsTabResults: eventsTabResults,
            dataTabData: dataTabData,
            allTabResults: allTabResults,
            eventsData: eventsData,
            nbAnalysisTabHits: nbAnalysisTabHits,
            nbDataTabHits: nbDataTabHits,
            nbEventsTabHits: nbEventsTabHits
        },
        operations: {
            handleNextPage: handleNextPage,
            handlePrevPage: handlePrevPage,
            handleFirstPage: handleFirstPage,
            handleLastPage: handleLastPage
        }
    };
};
