export var PaywallTypes;
(function(PaywallTypes) {
    PaywallTypes["Entitled"] = "Entitled";
    PaywallTypes["NotEntitled"] = "NotEntitled";
    PaywallTypes["NotEntitledLogin"] = "NotEntitledLogin";
    PaywallTypes["FreeWithReg"] = "FreeWithReg";
    PaywallTypes["Gifted"] = "Gifted";
    PaywallTypes["Default"] = "Default";
})(PaywallTypes || (PaywallTypes = {}));
export var AlignmentOption;
(function(AlignmentOption) {
    AlignmentOption["start"] = "start";
    AlignmentOption["end"] = "end";
    AlignmentOption["center"] = "center";
})(AlignmentOption || (AlignmentOption = {}));
export var ButtonSize;
(function(ButtonSize) {
    ButtonSize["full"] = "full";
    ButtonSize["fit"] = "fit";
    ButtonSize["fixed"] = "fixed";
})(ButtonSize || (ButtonSize = {}));
export var ButtonType;
(function(ButtonType) {
    ButtonType["button"] = "button";
    ButtonType["link"] = "link";
    ButtonType["circular"] = "circular";
    ButtonType["circularMobile"] = "circular mobile";
})(ButtonType || (ButtonType = {}));
export var ContentType;
(function(ContentType) {
    ContentType["article"] = "article";
    ContentType["podcast"] = "podcast";
    ContentType["dataGraphics"] = "data graphics";
    ContentType["webinar"] = "webinar";
    ContentType["dataviz"] = "dataviz";
    ContentType["event"] = "event";
    ContentType["page"] = "page";
    ContentType["company"] = "company";
    ContentType["product"] = "product";
    ContentType["target"] = "target";
    ContentType["conferences"] = "conferences";
    ContentType["disease_category"] = "disease category";
    ContentType["whitePaper"] = "white paper";
    ContentType["bciqReportFilterSet"] = "bciq report filter set";
    ContentType["bciqReportBookmark"] = "bciq custom report";
})(ContentType || (ContentType = {}));
export var HeadingOptions;
(function(HeadingOptions) {
    HeadingOptions["h1"] = "h1";
    HeadingOptions["h2"] = "h2";
    HeadingOptions["h3"] = "h3";
})(HeadingOptions || (HeadingOptions = {}));
export var Padding;
(function(Padding) {
    Padding["padding_none"] = "p-0";
    Padding["padding_0_5"] = "p-0.5";
    Padding["padding_1"] = "p-1";
    Padding["padding_1_5"] = "p-1.5";
    Padding["padding_2"] = "p-2";
    Padding["padding_2_5"] = "p-2.5";
    Padding["padding_3"] = "p-3";
    Padding["padding_3_5"] = "p-3.5";
    Padding["padding_4"] = "p-4";
    Padding["padding_5"] = "p-5";
    Padding["padding_6"] = "p-6";
    Padding["padding_7"] = "p-7";
    Padding["padding_8"] = "p-8";
    Padding["padding_9"] = "p-9";
    Padding["padding_10"] = "p-10";
})(Padding || (Padding = {}));
export var SizeOptions;
(function(SizeOptions) {
    SizeOptions["none"] = "none";
    SizeOptions["compact"] = "compact";
    SizeOptions["regular"] = "regular";
})(SizeOptions || (SizeOptions = {}));
export var StyleOptions;
(function(StyleOptions) {
    StyleOptions["primary"] = "primary";
    StyleOptions["secondary"] = "secondary";
    StyleOptions["info"] = "info";
})(StyleOptions || (StyleOptions = {}));
export var ViewType;
(function(ViewType) {
    ViewType["full"] = "Full";
    ViewType["preview"] = "Preview";
    ViewType["list"] = "List";
})(ViewType || (ViewType = {}));
export var Product;
(function(Product) {
    Product["BCIQ"] = "BCIQ";
    Product["BioCentury"] = "BioCentury";
    Product["Infinity"] = "Identity";
    Product["Conferences"] = "Conferences";
    Product["Profiles"] = "Profiles";
})(Product || (Product = {}));
export var MyWorkspaceActionType;
(function(MyWorkspaceActionType) {
    MyWorkspaceActionType["GET_FOLDERS"] = "getFolders";
    MyWorkspaceActionType["ADD_FOLDER"] = "addFolder";
    MyWorkspaceActionType["REMOVE_FOLDER"] = "removeFolder";
    MyWorkspaceActionType["RENAME_FOLDER"] = "renameFolder";
    MyWorkspaceActionType["SET_FOLDER"] = "setFolder";
    MyWorkspaceActionType["ADD_CARD"] = "addCard";
    MyWorkspaceActionType["REMOVE_CARD"] = "removeCard";
    MyWorkspaceActionType["MOVE_CARD"] = "moveCard";
})(MyWorkspaceActionType || (MyWorkspaceActionType = {}));
export var QueryModel;
(function(QueryModel) {
    QueryModel["articlesConnection"] = "articlesConnection";
    QueryModel["algolia"] = "algolia";
})(QueryModel || (QueryModel = {}));
