import { useEffect, useState } from "react";
import { useQuery } from "src/hooks/useQuery";
var useFilterCounter = function() {
    var router = useQuery();
    var query = router === null || router === void 0 ? void 0 : router.query;
    var ref = useState(0), filtersCounter = ref[0], setFiltersCounter = ref[1];
    useEffect(function() {
        var _a, _b, _c, _d, _e, _f;
        var totalNumber = 0;
        if ((query === null || query === void 0 ? void 0 : query.brs) !== "all") {
            if (Array.isArray(query === null || query === void 0 ? void 0 : query.brs)) {
                totalNumber += Number((_a = query === null || query === void 0 ? void 0 : query.brs) === null || _a === void 0 ? void 0 : _a.length);
            } else {
                totalNumber += 1;
            }
        }
        if ((query === null || query === void 0 ? void 0 : query.pd) !== "any") {
            totalNumber += 1;
        }
        if (query === null || query === void 0 ? void 0 : query.cm) {
            if (Array.isArray(query === null || query === void 0 ? void 0 : query.cm)) {
                totalNumber += Number((_b = query === null || query === void 0 ? void 0 : query.cm) === null || _b === void 0 ? void 0 : _b.length);
            } else {
                totalNumber += 1;
            }
        }
        if (query === null || query === void 0 ? void 0 : query.mt) {
            if (Array.isArray(query === null || query === void 0 ? void 0 : query.mt)) {
                totalNumber += Number((_c = query === null || query === void 0 ? void 0 : query.mt) === null || _c === void 0 ? void 0 : _c.length);
            } else {
                totalNumber += 1;
            }
        }
        if (query === null || query === void 0 ? void 0 : query.dc) {
            if (Array.isArray(query === null || query === void 0 ? void 0 : query.dc)) {
                totalNumber += Number((_d = query === null || query === void 0 ? void 0 : query.dc) === null || _d === void 0 ? void 0 : _d.length);
            } else {
                totalNumber += 1;
            }
        }
        if (query === null || query === void 0 ? void 0 : query.pr) {
            if (Array.isArray(query === null || query === void 0 ? void 0 : query.pr)) {
                totalNumber += Number((_e = query === null || query === void 0 ? void 0 : query.pr) === null || _e === void 0 ? void 0 : _e.length);
            } else {
                totalNumber += 1;
            }
        }
        if ((query === null || query === void 0 ? void 0 : query.ct) !== "all") {
            if (Array.isArray(query === null || query === void 0 ? void 0 : query.ct)) {
                totalNumber += Number((_f = query === null || query === void 0 ? void 0 : query.ct) === null || _f === void 0 ? void 0 : _f.length);
            } else {
                totalNumber += 1;
            }
        }
        setFiltersCounter(totalNumber);
    }, [
        query
    ]);
    return {
        filtersCounter: filtersCounter
    };
};
export default useFilterCounter;
