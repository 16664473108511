import { useContext, useMemo } from "react";
import { SearchPageContext } from "@contexts/SearchPageContext";
import { SEARCH_PAGE_ACTION_TYPES } from "@contexts/SearchPageContext/search-page.actions";
function useHandleBack(handleClose) {
    var ref = useContext(SearchPageContext), _state = ref.state, componentKey = _state.mobileModalComponent.componentKey, prevMobileModalComponent = _state.prevMobileModalComponent, dispatch = ref.dispatch;
    var handleBack = function() {
        if (!prevMobileModalComponent.componentKey || componentKey === "SortBy" || componentKey === "FilterBy" || componentKey === "SearchTips" || componentKey === "SaveSearch") {
            dispatch({
                type: SEARCH_PAGE_ACTION_TYPES.GET_PREV_MODAL_COMPONENT,
                data: {
                    title: undefined,
                    showActionButton: false,
                    componentKey: undefined
                }
            });
            handleClose();
        } else {
            dispatch({
                type: SEARCH_PAGE_ACTION_TYPES.CHANGE_MOBILE_MODAL_COMPONENT,
                data: {
                    title: prevMobileModalComponent.title,
                    showActionButton: prevMobileModalComponent.showActionButton,
                    componentKey: prevMobileModalComponent.componentKey
                }
            });
        }
    };
    return useMemo(function() {
        return {
            handleBack: handleBack
        };
    }, [
        handleClose
    ]);
}
export default useHandleBack;
